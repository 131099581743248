// protected.tsx
import { lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ADMIN_ROLE, USER_BANK_STATUS } from '../helper/constants';
import {
  JSONParse,
  getAccessTokenFromLocalStorage,
  getDecodedItemDataFromLocalStorage,
} from '../helper/storageHelper';
import useRefreshTokenHandler from '../hooks/useRefreshToken';
import { ISingnUpInfo } from '../interfaces/Auth';
import { IRoute } from '../interfaces/Route';
import { addUserData } from '../redux/reducer/loginUser';
import AdminDashboard from '../screens/adminpanel/dashboard';
import UserLibrary from '../screens/adminpanel/userlibrary';
import DashboardWrapper from '../screens/dashboard';
import Dashboard from '../screens/dashboard/home';
import PartnerRefer from '../screens/dashboard/partnerrefer';
import Tradeorder from '../screens/dashboard/tradeorder';
import HomePage from '../screens/mobilehomepage';
import Profile from '../screens/profile';
import UserDetails from '../screens/userdetails';
import {
  PATH_ADMINPANEL,
  PATH_AUTH,
  PATH_HOMEPAGE,
  PATH_ONBOARDING,
  PATH_OTC,
  PATH_PROFILE,
} from './path';

const UserOnboarding = lazy(
  () => import(/* webpackChunkName: "onboarding_chunk" */ '../screens/useronboarding'),
);
const AdminPanel = lazy(
  () => import(/* webpackChunkName: "admin_chunk" */ '../screens/adminpanel'),
);

export default function ProtectedRoutes(): IRoute[] {
  // const isAuthenticated = useSelector((state: RootState) => state.userAuth.accessToken);
  const isAuthenticated = getAccessTokenFromLocalStorage();

  // Call useRefreshToken if isAuthenticated is true
  useRefreshTokenHandler(!!isAuthenticated);

  const userDataString = getDecodedItemDataFromLocalStorage('userData');
  const userData: ISingnUpInfo | null = userDataString
    ? JSONParse<ISingnUpInfo>(userDataString)
    : null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData !== null && userData !== undefined) {
      dispatch(addUserData(userData));
    }
  }, [userData, dispatch]);

  const isAdmin = userData?.role === ADMIN_ROLE;
  // const isUserOnboarded = !isAdmin && userData?.pending_stage === 'false';
  const isUserOnboarded = !isAdmin && userData?.pending_stage === USER_BANK_STATUS;
  console.log({ isUserOnboarded });
  const adminRoutes = [
    {
      path: PATH_ADMINPANEL.adminpanel,
      element: <AdminPanel />,
      children: [
        {
          path: PATH_ADMINPANEL.children.userdetails,
          exact: true,
          element: <UserDetails />,
        },
        {
          path: PATH_ADMINPANEL.children.dashboard,
          exact: true,
          element: <AdminDashboard />,
        },
        {
          path: PATH_ADMINPANEL.children.library,
          exact: true,
          element: <UserLibrary />,
        },
        {
          path: '/',
          element: <Navigate to={PATH_ADMINPANEL.children.dashboard} />,
        },
      ],
    },
    // Fallback route for admin
    {
      path: '*',
      element: <Navigate to={PATH_ADMINPANEL.children.dashboard} replace />,
    },
  ];

  const userPreOnboardingRoutes = [
    // Route definitions for authenticated users
    {
      path: PATH_ONBOARDING.root,
      exact: true,
      element: <UserOnboarding />,
    },
    // Fallback route for new users
    {
      path: '*',
      element: <Navigate to={PATH_ONBOARDING.root} replace />,
    },
  ];

  const userPostOnboardingRoutes = [
    {
      path: '/',
      element: <DashboardWrapper />,
      children: [
        {
          path: PATH_OTC.dashboard,
          exact: true,
          element: <Dashboard />,
        },
        {
          path: PATH_OTC.tradeorder,
          exact: true,
          element: <Tradeorder />,
        },
        {
          path: PATH_OTC.partnerrefer,
          exact: true,
          element: <PartnerRefer />,
        },
        {
          path: PATH_PROFILE.profile,
          exact: true,
          element: <Profile />,
        },
        {
          path: PATH_HOMEPAGE.homePage,
          exact: true,
          element: <HomePage />,
        },
      ],
    },
    // Fallback route for on-boarded users
    {
      path: '*',
      element: <Navigate to={PATH_OTC.dashboard} replace />,
    },
  ];

  // Navigation as per user status
  let protectedRoutes;
  if (isAdmin) {
    protectedRoutes = adminRoutes;
  } else if (isUserOnboarded) {
    protectedRoutes = userPostOnboardingRoutes;
  } else {
    protectedRoutes = userPreOnboardingRoutes;
  }

  return isAuthenticated
    ? protectedRoutes
    : [
        // If not authenticated, redirect to login page
        {
          path: '*',
          exact: true,
          element: <Navigate to={PATH_AUTH.children.login} replace />,
        },
      ];
}
