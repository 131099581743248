import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '../../types/UserDetailType';
import { USER_BANK_STATUS } from '../../helper/constants';

export interface UserDataState {
  value: UserInfo | null;
}

const initialState: UserDataState = {
  value: null,
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    addUserData: (state, action: PayloadAction<UserInfo>) => {
      state.value = action.payload;
    },

    updatePendingStage: (state) => {
      if (state.value) {
        // Using Immer, you can directly "mutate" the state
        state.value.pending_stage = USER_BANK_STATUS;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUserData, updatePendingStage } = userDataSlice.actions;

export default userDataSlice.reducer;
