import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../components/loader';
import { ERRORS, KEY_CONF, MESSAGES, URL_CONF } from '../../../../helper/constants';
import { setEncodedItemDataToLocalStorage } from '../../../../helper/storageHelper';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import { useHandleLogin } from '../../../../hooks/useLogin';
import {
  IGoogleSSORegisterResponse,
  ISingnUpSSOState,
  ISingnUpSSOStateError,
} from '../../../../interfaces/Auth';
import userQueries from '../../../../queries/user';
import { PATH_ONBOARDING } from '../../../../routers/path';
import { QueryErrorResponse } from '../../../../types/UserDetailType';
import useAccountPopup from './AccountPopup.style';
import PhoneInput from 'react-phone-input-2';
import { capitalize, phoneNumberValidator } from '../../../../helper/validators';

type PopupProps = {
  open: boolean;
  handleClose: VoidFunction;
  userId: string | null;
};
interface PhoneInputProps {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}
const validateData = {
  name: 'name',
  email: 'email',
  phoneNumber: 'PhoneNumber',
  password: 'password',
  referralCode: 'referralCode',
};

function AccountType({ open, handleClose, userId }: PopupProps) {
  const classes = useAccountPopup();
  const handleLogin = useHandleLogin();
  const [accountType, setAccountType] = useState<string>('');
  const [referralCode, setReferralCode] = useState<string>('');
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState<ISingnUpSSOState>({
    phoneNumber: '',
    countryCode: '',
    contact: '',
  });
  const [errorState, setErrorState] = useState<ISingnUpSSOStateError>({
    phoneNumber: '',
  });
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const validateFields = (name: string, value: string) => {
    if (value.trim().length === 0) {
      setErrorState((prevState) => ({
        ...prevState,
        [name]: `${capitalize(name)} is required`,
      }));
      return;
    }
    switch (name) {
      case validateData.phoneNumber:
        setErrorState((prevState) => ({
          ...prevState,
          phoneNumber: !phoneNumberValidator(value) ? ERRORS.PHONE_ERROR : '',
        }));
        break;
      default:
        break;
    }
  };

  // Error handling---------------------------------------------
  const handleApiError = ({ response }: QueryErrorResponse) => {
    const message =
      response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
    if (message) {
      toast.error(message);
    }
    return true;
  };

  const { data: userData, refetch } = useGQLQuery(
    [KEY_CONF.AUTHENTICATION_GOOGLE_SIGNUP],
    userQueries.GOOGLE_SSO_REGISTER(userId || '', Number(accountType), state, referralCode),
    {},
    {
      enabled: false,
      onError: handleApiError,
    },
    URL_CONF.AUTHENTICATION,
  );

  useEffect(() => {
    if (userData && userData?.sso_register) {
      const { sso_register: result } = userData as unknown as IGoogleSSORegisterResponse;
      if (result?.access_token && result?.refresh_token) {
        const { access_token: accessToken, refresh_token: refreshToken, info, email } = result;
        handleLogin(accessToken, refreshToken);

        // Encode and save user login information in local storage
        setEncodedItemDataToLocalStorage('userData', JSON.stringify({ ...info, email }));
        toast.success(MESSAGES.USER_LOGIN);
        navigate(PATH_ONBOARDING.root);
        // Handle success, e.i., close the dialog
        setLoader(false);
        handleClose();
      } else {
        // Handle the case where access_token is null
        toast.error('Access token is null');
      }
    }
    setLoader(false);
  }, [userData, handleClose, handleLogin, navigate]);

  const handleChangeAccountType = (event: SelectChangeEvent) => {
    setAccountType(event.target.value);
  };

  const handlePhone = (value: string | null, data: PhoneInputProps) => {
    setState((prevState) => ({
      ...prevState,
      phoneNumber: value !== null ? `${value}` : '',
      countryCode: data.dialCode,
      contact: value !== null ? value.slice(data.dialCode.length) : '',
    }));
    if (value === '' && value.trim().length === 0) {
      setErrorState((prevState) => ({
        ...prevState,
        phoneNumber: `${capitalize(validateData.phoneNumber)} is required`,
      }));
    } else {
      validateFields(validateData.phoneNumber, value || validateData.phoneNumber);
    }
  };

  const handleChangeReferral = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setReferralCode(e.target.value);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    try {
      setLoader(true);
      refetch();
    } catch (error) {
      // Handle any synchronous errors
      setLoader(false);
      toast.error('Synchronous Error:');
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.modelStyle}
      >
        {loader && <Loader />}
        <Box className={classes.headerAccountStyle}>
          <Typography variant="h5">
            <span>
              <CloseIcon onClick={handleClose} />
            </span>
          </Typography>
          <Typography variant="h2">Select your Account type</Typography>
          {/* <Typography variant="subtitle2">
                        </Typography> */}
        </Box>
        <form onSubmit={handleSubmit}>
          <Box className={classes.rightBodyInput}>
            <InputLabel>Account Type*</InputLabel>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={accountType}
                onChange={handleChangeAccountType}
              >
                <MenuItem value="1">Personal</MenuItem>
                <MenuItem value="2">Business</MenuItem>
                <MenuItem value="3">Charity</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className={`${classes.rightBodyInput} ${classes.telephoneBoxStyle}`}>
            <InputLabel>Phone Number *</InputLabel>
            <PhoneInput
              country="us"
              placeholder="Enter Your Phone Number"
              value={state.phoneNumber}
              onChange={handlePhone}
              disableCountryGuess
            />
            <span className={classes.errorStyle}>{errorState.phoneNumber}</span>
          </Box>
          <Box className={classes.rightBodyInput}>
            <InputLabel>Referral Code (Optional)</InputLabel>
            <TextField
              id="outlined-basic-email"
              value={referralCode}
              type="text"
              autoComplete="off"
              name="email"
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChangeReferral(e)}
            />
          </Box>
          <Box className={classes.checkboxStyle}>
            <input type="checkbox" id="html" checked={isChecked} onChange={handleCheckboxChange} />
            <InputLabel>
              I accept the
              <a href="/VPay Terms of Services.pdf" download>
                <span>Terms & Conditions</span>
              </a>
            </InputLabel>
          </Box>
          <Box
            className={
              accountType && isChecked
                ? classes.accountTypeConfirmButton
                : classes.confirmButtonDisabled
            }
          >
            <button type="submit" disabled={!(accountType && isChecked)}>
              Confirm
            </button>
          </Box>
        </form>
      </Dialog>
    </div>
  );
}

export default AccountType;
