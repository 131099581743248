import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useAccountPopup = makeStyles((theme: Theme) => ({
  modelStyle: {
    '& .MuiDialog-container': {
      [theme.breakpoints.between('xs', 'sm')]: {
        alignItems: 'flex-end',
      },
    },
    '& .MuiDialog-paper': {
      width: '407px',
      borderRadius: '14px',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100%',
        margin: '0px',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '20px 20px 0px 0px',
      },
      '& img': {
        margin: '0px auto',
      },
      '& h2': {
        color: 'rgba(31, 31, 31, 1)',
        fontFamily: 'Inter-Bold',
        marginTop: '30px',
        [theme.breakpoints.between('xs', 'sm')]: {
          marginTop: '12px',
        },
      },
      '& h6': {
        color: 'rgba(143, 143, 143, 1)',
        marginTop: '2px',
      },
      '& h5': {
        padding: '0px 15px 0px 0px',
        textAlign: 'right',
        '& svg': {
          color: 'rgba(158, 158, 158, 1)',
          fontSize: '23px',
          cursor: 'pointer',
        },
      },
    },
  },
  errorStyle: {
    color: 'red',
    fontSize: '12px',
  },
  telephoneBoxStyle: {
    '& input': {
      '&.form-control': {
        borderRadius: '10px',
        lineHeight: 'normal',
        height: 'auto',
        padding: '10.9px 14px 10.9px 48px',
        width: '100%',
        [theme.breakpoints.between('xs', 'sm')]: {
          fontSize: '16px',
        },
      },
    },
    '& .selected-flag': {
      borderRadius: '10px 0 0 10px',
    },
    '& .flag-dropdown': {
      borderRadius: '10px 0 0 10px',
      backgroundColor: '#fff',
    },
  },
  headerAccountStyle: {
    padding: '15px 0px 25px 15px',
    backgroundColor: 'rgba(246, 246, 246, 1)',
  },
  accountTypeConfirmButton: {
    padding: '20px 15px',
    '& button': {
      border: 'none',
      backgroundColor: 'rgba(25, 104, 182, 1)',
      borderRadius: '10px',
      color: '#fff',
      fontSize: '17px',
      padding: '12px',
      width: '100%',
      cursor: 'pointer',
    },
  },
  rightBodyInput: {
    padding: '15px 15px 10px 15px',
    '& p': {
      textAlign: 'right',
      color: 'rgba(156, 0, 21, 1)',
      marginTop: '7px',
      cursor: 'pointer',
      '& a': {
        color: 'rgba(156, 0, 21, 1)',
      },
    },
    '& input': {
      fontFamily: 'Inter-Medium',
      padding: '10.4px 14px',
      fontSize: '16px',
      color: 'rgba(31, 31, 31, 1)',
    },
    '& .MuiSelect-select': {
      fontFamily: 'Inter-Medium',
      padding: '10.4px 14px',
      fontSize: '14px',
      color: 'rgba(31, 31, 31, 1)',
    },
    '& label': {
      color: 'rgba(26, 76, 142, 1)',
      marginBottom: '5px',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      borderRadius: '10px',
    },
    '& fieldset': {
      border: '1px solid rgba(198, 198, 198, 1) !important',
    },
  },
  checkboxStyle: {
    margin: '10px 0px 30px 20px',
    display: 'flex',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: '20px',
    },
    '& input': {
      padding: '0',
      height: 'initial',
      width: 'initial',
      marginBottom: '0',
      cursor: 'pointer',
    },
    '& label': {
      paddingLeft: '5px',
      color: 'rgba(31, 31, 31, 1)',
      fontSize: '14px',
      position: 'relative',
      cursor: 'pointer',
      '& span': {
        textDecoration: 'underline',
        marginLeft: '4px',
      },
    },
  },
  confirmButtonDisabled: {
    padding: '20px 15px',
    '& button': {
      color: '#fff',
      backgroundColor: 'rgba(191, 207, 231, 1)',
      borderRadius: '10px',
      textAlign: 'center',
      padding: '12px 0px',
      border: 'none',
      width: '100%',
      fontSize: 17,
      fontFamily: 'Inter-Medium',
    },
  },
}));

export default useAccountPopup;
